/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-09",
    versionChannel: "nightly",
    buildDate: "2024-08-09T00:05:34.468Z",
    commitHash: "b9054c4f45240a46d1bc91659944b78c87a4348a",
};
